<template>
<div class="page-image-holder">
     <b-img :src="imagesrc" class="w-100 page-image" />
     <PageTitle :title="title"></PageTitle>
</div>
</template>

<script>
const PageTitle = () => import("@/components/page/PageTitle");

export default {
      name: 'Image',
      props: ["image", "title"],
      components:{PageTitle},
      computed:{
            imagesrc(){
                  return this.$images.pageImage(this.image);
            }
      }
};
</script>
